import React, { FC, useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Empty,
  Form,
  Radio,
  Row,
  Space,
  Typography,
} from 'antd';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom';

import { Layout } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { billingModel, EffectToAddType } from '@/entities/billing';
import { PATHS } from '@/shared/config';
import { shouldAskAdditionalInfo } from '@/shared/lib/shouldAskAdditionalData';
import { AdditionalDataForm } from '@/features/additionalDataForm';
import {
  GroupNamesDefaultMessage,
  lifeTimeMonthDefaultMessages,
  ServiceNamesDefaultMessage,
} from '@/shared/config/i18n';
import { organizationModel } from '@/entities/organization';
import { userModel } from '@/entities/user';

import styles from './AddPackages.module.scss';

const { Title, Text } = Typography;

type AddPackagesFormType = {
  packageID?: string;
};

const selectNewOrderLoadingState =
  billingModel.selectors.getLoadingStateSelector('newOrder');

export const AddPackages: FC = () => {
  const [isAdditionalFormOpen, setIsAdditionalFormOpen] =
    useState<boolean>(false);

  const { organizationID, userID } =
    useParams<{ organizationID: string; userID: string }>();

  const navigate = useNavigate();

  const organization = useAppSelector(
    organizationModel.selectors.selectOrganization,
  );
  const currentUser = useAppSelector(userModel.selectors.selectCurrentUser);
  const packages = useAppSelector(
    billingModel.selectors.selectAvailablePackages,
  );
  const newOrderLoadingState = useAppSelector(selectNewOrderLoadingState);

  const {
    ID: accountID,
    BillingZones,
    BillingInformation,
  } = useAppSelector(billingModel.selectors.selectAccountInfo);

  const BillingZone = BillingZones?.at(0);

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();
  // TODO [2|l] devtools throw warning that form doesnt connect to any Form component but its connected. Need to solve this issue
  const [form] = Form.useForm<AddPackagesFormType>();

  const onFinish = async (data: AddPackagesFormType) => {
    const targetPackage = packages?.find((pack) => pack.SKU === data.packageID);

    const effectToAdd: EffectToAddType = {
      case: 'PackageAdd',
      value: {
        Package: targetPackage,
      },
    };

    if (
      shouldAskAdditionalInfo(BillingZone, BillingInformation) &&
      Number(targetPackage?.Price ?? 0) > 0
    ) {
      dispatch(billingModel.actions.setEffectToAdd(effectToAdd));

      setIsAdditionalFormOpen(true);
    } else {
      try {
        dispatch(
          billingModel.actions.setLoading({
            loaderKey: 'newOrder',
            loaderState: 'pending',
          }),
        );

        await dispatch(
          billingModel.thunks.createOrder({
            AccountID: accountID,
            Effect: effectToAdd,
          }),
        );

        navigate(generatePath(PATHS.organization, { organizationID, userID }));
      } catch {
        dispatch(
          billingModel.actions.setLoading({
            loaderKey: 'newOrder',
            loaderState: 'failed',
          }),
        );
      } finally {
        dispatch(
          billingModel.actions.setLoading({
            loaderKey: 'newOrder',
            loaderState: 'idle',
          }),
        );
      }
    }
  };

  useEffect(() => {
    const getProducts = async () => {
      const { AvailableProducts } = await dispatch(
        billingModel.thunks.getAvailableProducts({
          Condition: { case: 'ByAccountID', value: accountID },
        }),
      ).unwrap();

      dispatch(billingModel.actions.setAvailableProducts(AvailableProducts));
    };

    if (accountID) {
      getProducts();
    }
  }, [accountID]);

  return (
    <Layout.Content>
      <Breadcrumb
        className="p2"
        style={{ padding: '24px 0' }}
        items={[
          {
            title: (
              <Link className={styles.breadcrumb} to={generatePath(PATHS.main)}>
                <FormattedMessage id="breadcrumb.home" defaultMessage="Home" />
              </Link>
            ),
          },
          {
            title: currentUser && (
              <Link
                className={styles.breadcrumb}
                to={generatePath(PATHS.user, {
                  userID,
                })}
              >
                {`${currentUser?.PersonalData?.FirstName ?? ''} ${
                  currentUser?.PersonalData?.LastName ?? ''
                }`}
              </Link>
            ),
          },
          {
            title: (
              <Link
                className={styles.breadcrumb}
                to={generatePath(PATHS.organization, {
                  organizationID,
                  userID,
                })}
              >
                {organization?.Name ?? ''}
              </Link>
            ),
          },
          {
            title: (
              <FormattedMessage
                id="newPackagePage.title"
                defaultMessage="New package"
              />
            ),
          },
        ]}
      />

      <Form
        form={form}
        name="add-package"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={32}>
          <Col span={12}>
            <Title level={4}>
              <FormattedMessage
                id="packages.stepOne"
                defaultMessage="Select package"
              />
            </Title>

            <Form.Item
              name="packageID"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'forms.required',
                    defaultMessage: 'Field is required',
                  }),
                },
              ]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  {packages.map(
                    ({
                      SKU,
                      Name,
                      Price,
                      Currency,
                      Services,
                      Kind: packageKind,
                    }) => (
                      <Radio key={SKU} value={SKU}>
                        <>
                          <Title level={5}>
                            <>
                              <Text>{Name}</Text>
                              {' | '}
                              <FormattedNumber
                                maximumFractionDigits={0}
                                value={Number(Price ?? '0')}
                                // eslint-disable-next-line react/style-prop-object
                                style="currency"
                                currencyDisplay="narrowSymbol"
                                currency={Currency}
                              />
                              {' | '}

                              {packageKind.case === 'Package' &&
                                formatMessage(
                                  lifeTimeMonthDefaultMessages[
                                    packageKind.value.LifeTimeMonths
                                  ],
                                )}
                            </>
                          </Title>

                          <ul>
                            {Services?.map(({ Types, Group, Kind }) => (
                              <li key={`${Types.toString()}`}>
                                {Types.length > 1
                                  ? formatMessage(
                                      GroupNamesDefaultMessage[Group],
                                    )
                                  : formatMessage(
                                      ServiceNamesDefaultMessage[Types.at(0)],
                                      {
                                        amount:
                                          Kind.case === 'Countable'
                                            ? Number(Kind.value.Limit)
                                            : 0,
                                      },
                                    )}
                              </li>
                            ))}
                          </ul>
                        </>
                      </Radio>
                    ),
                  )}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        {packages.length > 0 ? (
          <Button
            type="primary"
            htmlType="submit"
            loading={newOrderLoadingState === 'pending'}
          >
            <span>
              <FormattedMessage
                id="newPackagePage.addNewPackage"
                defaultMessage="Add new package"
              />
            </span>
          </Button>
        ) : (
          <Empty
            description={
              <FormattedMessage
                id="packages.noPackages"
                defaultMessage="No packages"
              />
            }
          />
        )}
      </Form>

      <AdditionalDataForm
        isOpen={isAdditionalFormOpen}
        onCloseHandler={() => setIsAdditionalFormOpen(false)}
      />
    </Layout.Content>
  );
};
